<template>
	<div class="app-container body-tabs-shadow"> <!--app-theme-white-->
		<div class="app-container">
			<div class="row no-gutters h-screen">
				<div class="col-md-6" :class="{'theme-side': systemMode === 'main'}"
					 :style="GET_COMPANY_SUBSCRIPTION_SETTING.menu_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.menu_color : 'background:#181C4C'">
					<div class="p-4 flex flex-col space-y-5">
						<div class="mb-5" v-if="systemMode === 'whitelabel'">
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-12 logo-top md:pl-20 h-12 logo"
									 :src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ GET_COMPANY_SUBSCRIPTION_SETTING.seller_login_description }}
							</div>
						</div>
						<div class="mb-5" v-else>
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="h-12 img-fluid md:h-auto logo-top md:pl-20 logo"
									 src="./../../../assets/images/logo/logo.png" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ $t('welcomeEntry') }}
							</div>
						</div>
						<!-- <div class="md:mt-20">
							<div class="no-gutters row flex justify-center mt-20 mb-5" v-if="loading">
								<svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
								<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
								</svg>
							</div>
							<div class="flex justify-center mt-5" v-else>
								<button @click="getPermissionUrl" class="bg-blue-900 px-3 py-2 rounded shadow-sm text-xl md:w-1/2">
									<div class="flex justify-center items-center m-auto" v-if="processing">
										<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
										<span class="text-white">{{ $t('connecting') }}</span>
									</div>
									<span class="m-auto text-white" v-else>{{ $t('connectNow') }}</span>
								</button>
							</div>
						</div> -->
					</div>
				</div>
				<div class="col-md-6">
					<div class="p-4 flex flex-col space-y-5">
						<div class="mb-5">
							<div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!--								<div class="font-semibold text-lg theme-header-size text-blue-900 md:tracking-wide">-->
								<!--									{{ $t('forgetPassword') }}-->
								<!--								</div>-->
							</div>
						</div>
						<div class="flex justify-end md:mt-20">
							<div class="login-border-section w-full lg:w-4/5 md:pl-10 md:pr-10">
								<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
									<form class="mt-5 mb-5" @submit.prevent="handleSubmit(forget)">
										<div class="form-group">
											<label for="exampleEmail" class="text-base text-blue-900 font-semibold">{{
													$t('emailAddress')
												}}</label>
											<div>
												<ValidationProvider name="email" rules="required|email"
																	v-slot="{ errors }">
													<input v-model="email" data-cy='email' name="email" type="email"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>

										<div class="form-group">
											<label for="exampleEmail" class="text-base"></label>
											<div>
												<button data-cy='forget-btn'
														:style="GET_COMPANY_SUBSCRIPTION_SETTING.button_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.button_color : 'background:#E8B105'"
														class="w-full login-btn-success px-4 py-2 rounded-md"
														type="submit" :disabled="invalid || processing">
													<span class="text-white"
														  v-if="processing">{{ $t('resettingPassword') }}</span>
													<span class="text-white" v-else>{{ $t('resetPassword') }}</span>
												</button>
											</div>
										</div>
										<div class="form-group">
											<label for="exampleEmail" class="text-base"></label>
											<div>
												<span class="font-md-login"><router-link :to="{name : 'login'}"
																						 data-cy='login-page'
																						 class="no-deco no-de-hover text-blue-900">{{
														$t('toLoginPage')
													}}</router-link></span><br><br>
												<!-- <span class="font-md-login">{{ $t('notCus') }}? <router-link :to="{name : 'register'}" class="no-deco no-de-hover link-color">{{ $t('welcome') }}</router-link></span> -->
											</div>
										</div>
										<div class="form-group mt-10">
											<div>
												<!-- <span class="font-md-login text-base text-gray-900">{{ $t('golf') }}
													<a target="_blank" href="https://golf.automatiseramera.se/login/" class="font-semibold text-base hover:no-underline text-blue-700">
														{{ $t('login') }}
													</a>
												</span><br><br> -->
											</div>
										</div>
									</form>
								</ValidationObserver>
							</div>
						</div>
					</div>
					<div class="container text-center md:bottom-0 md:absolute mb-4 flex justify-center">
                        <span>Copyright © <span v-if="systemMode === 'whitelabel'">{{
								current
							}} {{ GET_COMPANY_SUBSCRIPTION_SETTING.brand_name }}</span><span
							v-else>{{ current }} B2B Portal</span></span>
						<span>
							<locale-component :show-text="true" :height="5" :width="5"/>
                        </span>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'
import i18n from '@/plugins/i18n'
import LocaleComponent from "../../../components/LocaleComponent";

export default {
	name: 'forget',
	components: {
		Notification: () => import('./../../../customComponent/NotificationAlert'),
		ValidationProvider,
		ValidationObserver,
		LocaleComponent
	},
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			loaded: false,
			email: '',
			systemMode: '',
			processing: false
		}
	},
	computed: {
		...mapGetters({
			// USER_TOKEN : 'authUSER_TOKEN',
			// GET_USER_UTIL : 'GET_USER_UTIL',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'
		})
	},
	beforeMount() {
		// If user token is set, proceed to dashboard
		// if (this.USER_TOKEN) {
		//     // console.log(this.USER_TOKEN)
		//     if (this.GET_USER_UTIL === true) {
		//         window.location.href = this.baseURL + '/accountant'
		//     } else {
		//         window.location.href = this.baseURL + '/automatisera'
		//     }
		// }
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
		if (process.env.VUE_APP_SYSTEM_MODE === 'whitelabel') {
			this.$store.dispatch('auth/getCompanySetup', {company_id: process.env.VUE_APP_COMPANY_ID}).then(resp => {
				this.$store.commit('portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING', resp.data.data)
			})
		}
	},
	methods: {
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
			localeChanged()
		},
		forget() {
			if (this.email === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.email.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else if (!this.$services.helpers.validateEmailAddress(this.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			} else {
				this.processing = true
				this.$store.dispatch('auth/forgetPassword', {email: this.email})
					.then(_ => {
						this.processing = false
						this.$services.helpers.notification(this.$t('checkEmail'), 'success', this)
					})
					.catch(err => {
						this.processing = false
						this.$services.helpers.notification(err.response.data.message, 'error', this)
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

// .form-control{
//     // border: 1px solid $form-border !important;
// }
.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #E8B105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 177px;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px
	}
}
</style>
